import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import axios from 'axios'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueGtag from "vue-gtag";

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_MAPS,
    libraries: 'places',
  }
});

if (process.env.VUE_APP_GTAG) {
  Vue.use(VueGtag, {
      config: { id: "G-EBSJL0GZCC" }
  }, router);
}

if(location.host.split(":")[0] == 'localhost')
  window.URL = "http://"+process.env.VUE_APP_URL;
else
  window.URL = "https://"+process.env.VUE_APP_URL;

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

Vue.config.productionTip = false

new Vue({
  router,
  render: function (h) { return h(App) }
}).$mount('#app')

import '@/assets/stylo.css'
