<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App',
  methods: {
    formatDate: function(format, fecha, actual){
      var date;
      if(fecha)
        date = new Date(fecha);
      else if(actual)
        date = new Date();

      format = format.replaceAll(/\bd\b/g, String(date.getDate()).padStart(2, '0'));
      format = format.replaceAll(/\bj\b/g, String(date.getDate()));
      format = format.replaceAll(/\bn\b/g, String(date.getMonth()+1));
      format = format.replaceAll(/\bm\b/g, String(date.getMonth()+1).padStart(2, '0'));
      format = format.replaceAll(/\bY\b/g, String(date.getFullYear()));
      //HORA
      format = format.replaceAll(/\bH\b/g, String(date.getHours()));
      format = format.replaceAll(/\bi\b/g, String(date.getMinutes()).padStart(2, '0'));
      format = format.replaceAll(/\bs\b/g, String(date.getSeconds()).padStart(2, '0'));

      return format;
      //return date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate();//YYYY-MM-dd
      //return String(date.getDate()).padStart(2, '0')+'/'+String(date.getMonth()+1).padStart(2, '0')+'/'+date.getFullYear();//dd-MM-YYYY
    },
  }
}
</script>
