import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Buscador',
    component: function () {
      return import('@/views/buscador.vue')
    }
  },
  {
    path: '/doctores/:doctor',
    name: 'Doctor',
    component: function () {
      return import('@/views/buscador.vue')
    }
  },
  {
    path: '/buscador/:pais/:provincia?/:ciudad?',
    name: 'Ciudad',
    component: function () {
      return import('@/views/buscador.vue')
    }
  },
  {
    path: '/noticias/:slug?',
    name: 'Noticias',
    component: function () {
      return import('@/views/buscador.vue')
    }
  }
]

const router = new VueRouter({
  scrollBehavior (to, from, savedPosition) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({ x: 0, y: 0 })
        }, 500)
      })
  },
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
